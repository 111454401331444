html {
	font-size: 14px;
	scroll-behavior: smooth;
}

body {
	padding: 0;
	margin: 0;
	font-size: 16px;
	color: var(--body-clr);
	line-height: 26px;
	overflow-x: hidden;
	font-family: var(--body-fonts);
	background: var(--body-bg);
	@include breakpoint(sm) {
		font-size: 18px;
		line-height: 28px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	line-height: 1.3;
	color: var(--title-clr);
	font-family: var(--heading-fonts);
	font-weight: 700;
	a {
		color: var(--title-clr);
		&:hover {
			color: var(--base-clr);
		}
	}
}
h1 {
	font-size: 28px;
}
h2 {
	font-size: 22px;
	line-height: 32px;
}
h3 {
	font-size: 18px;
	line-height: 28px;
}
h4 {
	font-size: 15px;
	line-height: 24px;
}
h5 {
	font-size: 14px;
}
h6 {
	font-size: 14px;
}
p {
	margin-top: -9px;
	&:last-child {
		margin-bottom: -7px !important;
	}
}
@include breakpoint(sm) {
	h1 {
		font-size: 30px;
	}
	h2 {
		font-size: 24px;
		line-height: 34px;
	}
	h3 {
		font-size: 18px;
		line-height: 28px;
	}
	h4 {
		font-size: 16px;
		line-height: 26px;
	}
	h5 {
		font-size: 15px;
	}
}
@include breakpoint(md) {
	h1 {
		font-size: 32px;
		line-height: 40px;
	}
	h2 {
		font-size: 26px;
		line-height: 36px;
	}
	h3 {
		font-size: 20px;
		line-height: 30px;
	}
	h4 {
		font-size: 18px;
		line-height: 26px;
	}
	h5 {
		font-size: 16px;
	}
}
a {
	display: inline-block;
	transition: all ease 0.5s;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
}
ul {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
	}
}
label,
button,
select {
	cursor: pointer;
}
input,
textarea {
	width: 100%;
}
